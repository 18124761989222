/* You can add global styles to this file, and also import other style files */
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

@import "mdb-angular-ui-kit/assets/scss/mdb.scss";

@import "vidstack/player/styles/default/theme.css";
@import "vidstack/player/styles/default/layouts/video.css";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

// Carousel
@import '@glidejs/glide/src/assets/sass/glide.core';
@import '@glidejs/glide/src/assets/sass/glide.theme';

// Select
@import "~@ng-select/ng-select/themes/material.theme.css";

$eo-theme-primary: #1266f1; 
$eo-theme-secondary: #9fa6b2; 
$eo-theme-success: #00b74a; 
$eo-theme-danger: #f93154; 
$eo-theme-warning: #ffa900; 
$eo-theme-info: #39c0ed; 
$eo-theme-light: #fbfbfb; 
$eo-theme-dark: #262626; 
$eo-theme: mdb-light-theme($eo-theme-primary, $eo-theme-secondary);
@include mdb-theme($eo-theme);

:root {
  --footer: #fbfbfb;
  --footer-darker: rgba(0, 0, 0, 0.05);
}

$eo-dark-theme-primary: #1266f1;
$eo-dark-theme-secondary: #b23cfd;
$eo-dark-theme: mdb-dark-theme($eo-theme-primary, $eo-theme-secondary);



.dark {
  @include mdb-theme($eo-dark-theme);
  --footer: #2b2b2b;
  --footer-darker: #222222;
  .bg-gradient-animation::after {
    background-image: url('/assets/dark-wave-transition.svg') !important;
  }
  .navToolbar {
    background: #222222;
  }
}

// Sidenav
.sidenav-primary .sidenav-link.active {
  color: var(--mdb-gray-700);
  font-weight: 600;
}

.modal-dialog-scrollable {
  height: 100% !important;
}

.navbar-nav .nav-link {
  color: unset;
}

.modal-dialog-scrollable {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.pointer {
  cursor: pointer !important;
}

.hoverUnderline:hover {
  text-decoration: underline;
}

.skeleton {
  background-color: #ececec;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 35%,
    rgba(255, 255, 255, 0.7) 45%,
    rgba(255, 255, 255, 0) 55%
  );
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 250% 100%;
  border-radius: 10px;
  line-height: inherit;
  cursor: progress;
  animation: skeleton-keyframes 2s infinite;
}

@keyframes skeleton-keyframes {
  to {
    background-position: 0 100%;
  }
}

.disable-svg-animation .bg-gradient-animation {
  animation: none !important; /* Stops all animations */
  background-image: url('/assets/tide-bg-no-animation.svg') !important;
}

.bg-gradient-animation {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  background-color: #0b3c8d;
  background-image: url('/assets/tide-bg.svg');
}

.bg-gradient-animation::after {
  content: "";
  position: absolute;
  left: -2.5vw;
  width: 105vw;
  height: 100vw;
  background-image: url('/assets/wave-transition.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
}

@media (max-width: 700px) {
  .bg-gradient-animation::after {
    bottom: -5px;
  }
}

@media (min-width: 700px) and (max-width: 1100px) {
  .bg-gradient-animation::after {
    bottom: -10px;
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .bg-gradient-animation::after {
    bottom: -25px;
  }
}

@media (min-width: 1600px) and (max-width: 2900px) {
  .bg-gradient-animation::after {
    bottom: -40px;
  }
}
@media (min-width: 2900px) {
  .bg-gradient-animation::after {
    bottom: -60px;
    left: 50vm;
    width: 150vm;
  }
}

.input-container {
  //width: 89%;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group-text {
  background-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0rem;
  padding: 0.5rem 0rem 0rem 0.75rem;
}
.input-group button.btn-input-group {
  padding: 0px;
  vertical-align: baseline;
}

.stepper-completed .stepper-head-icon {
  background-color: #757575 !important;
}
.stepper-active .stepper-head-icon {
  background-color: #1266f1 !important;
}
.stepper-invalid .stepper-head-icon {
  background-color: #f93154 !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Tabs
.nav-tabs {
  border-color: #dfdfdf;
  border-width: 0 0 1px 0;
  border-style: solid;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: black;
  text-transform: unset !important;
  border-radius: 1.5px;
  border-bottom: 0.1875rem solid #1266f1;
}
.nav-tabs .nav-link {
  text-decoration: none;
  color: #767676;
  text-transform: unset !important;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.nav-tabs .nav-link:hover {
  color: #484848;
  background-color: transparent;
}
.nav-tabs .nav-link.disabled {
  color: #dfdfdf;
}


// Forms & Inputs
textarea {
  min-height: 85px !important;
  line-height: 1.5em !important;
}

// Button Inputs
.button-input-no-outline {
  cursor: pointer;
}
.button-input-no-outline:focus-visible {
  outline-color: transparent !important;
}

// Datepicker
.datepicker {
  color: transparent;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  position: absolute;
  z-index: 500;
}
.datepicker:focus-visible {
  outline-color: transparent !important;
}
.md-drppicker .ranges ul li button.active {
  background-color: #f5f5f5 !important;
  color: #1266f1 !important;
  border-radius: 5px !important;
}
.md-drppicker td.active, .md-drppicker td.active:hover {
  background-color: #1266f1 !important;
  border-color: transparent !important;
  color: #fff !important;
  border-radius: 5px !important;
}
.md-drppicker .btn, .md-drppicker .btn:focus, .md-drppicker .btn:hover {
  background-color: #1266f1 !important;
  border-radius: 5px !important;
}
.ngx-daterangepicker-action {
  font-size: 14px;
}

// SideNav
.sidenav {
    top: 0;
    height: 100%;
    width: 350px !important;
    position: fixed !important;
}
@media (max-width: 450px) {
    .sidenav {
        width: 100% !important;
    }
}
.sidenav-link.active {
    background-color:#dbdee5 ;
}
.sidenav-link-sub.active {
  background-color:#dbdee5 !important;
}

.sidenav-collapse {
  background-color: rgba(219, 222, 229, 0.3);
}

.sidenav-link:hover {
    background-color: #e9ebef;
}
.sidenav-primary .sidenav-item .sidenav-link2:hover,.sidenav-link-sub:hover {
  background-color: #e9ebef;
}
.sidenav-primary .sidenav-item .sidenav-link:hover {
  background-color: #e9ebef !important;
}

// Google Maps Autocomplete
.pac-container {
  z-index: 5000
}

// Video Player

// #media-menu-button-1 {
//   /* CSS rules */
//   display: none !important;
// }

@media only screen and (min-width: 1200px) {
  [aria-label="Chapters"] {
    /* CSS rules */
    display: none !important;
  }
}

.vds-accessibility-menu .vds-menu-item {
  display: none;
}

// .vds-thumbnail {
//   display: none;
// }


:where(.vds-menu-items) {
  --media-menu-checkbox-bg-active: var(--mdb-success) !important;
  --default-text: var(--mdb-black) !important;
}


:where(.vds-live-button[data-edge] .vds-live-button-text) {
  background-color: var(--media-live-button-edge-bg, #ff274a);
  color: var(--media-live-button-edge-color, #f5f5f5);
}

:where([data-live] .vds-time-slider .vds-slider-track-fill) {
  background-color: var(--media-live-track-fill, #ff274a);
}


// Carousel
.glide__bullet {
  background-color: #a9a9a9 !important
}


.glide__bullet--active {
  background-color: #000000 !important
}

// MDB Dropdown
.tableDropdown {
  right: -10px;
}

// Ng Select
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: var(--mdb-dark);
}

.ng-select.ng-select-focused .ng-select-container:after,
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after {
    border-color: var(--mdb-primary);
}


.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: var(--mdb-gray-300);
  border-radius: 12px;
  color: var(--mdb-black);
    z-index: 5000;
  position: relative;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  color: var(--mdb-black);
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  color: vaR(--mdb-gray);
}
ng-select.ng-invalid.ng-touched .ng-select-container {
  border: 1px solid var(--mdb-danger);
  border-radius: 5px;
}

.cdk-overlay-container .cdk-global-overlay-wrapper:first-child .toast {
  margin-top: 4.1em;
}

// For non datatable lists (zebra stripes)
.odd {
  background-color: var(--mdb-gray-200) !important;
}

.even {
  background-color: var(--mdb-gray-100) !important;
}

// Navigation
.navToolbar {
  background: rgba(255, 255, 255);
}